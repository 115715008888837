class SearchForm {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.searchForm = document.querySelector("#searchform");
			cache.searchInput = document.querySelector('#searchform input[name="s"]');
			cache.submitButton = document.querySelector(
				'#searchform input[type="submit"]'
			);
		};

		let registerEvents = () => {
			if (cache.submitButton) {
				cache.submitButton.disabled = true;

				cache.searchInput.addEventListener("input", function() {
					if (cache.searchInput.value.trim().length > 1) {
						cache.submitButton.disabled = false;
					} else {
						cache.submitButton.disabled = true;
					}
				});
			}
		};

		initFunc();
	}
}

export { SearchForm };
