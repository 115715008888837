class SliderQuotes {
	constructor() {
		const cache = {};
		let count = 0;

		const initFunc = () => {
			initCache();
			registerEvents();
		};

		const initCache = () => {
			cache.sliderQuotes = document.querySelector(".slider-quotes");
			if (cache.sliderQuotes) {
				cache.slides = cache.sliderQuotes.querySelectorAll(".swiper-slide");
				cache.logos = cache.sliderQuotes.querySelectorAll(".swiper-slide .image");
				cache.pagination = cache.sliderQuotes.querySelector(".swiper-pagination");
			}
		};

		const registerEvents = () => {
			initSlider();
		};

		const initSlider = () => {
			const slideCount = cache.slides ? cache.slides.length : 0;

			if (cache.sliderQuotes && slideCount > 1) {
				const swiper = new Swiper(".slider-quotes", {
					centeredSlides: true,
					loop: true,
					pagination: {
						el: cache.pagination, // Associe la pagination spécifique à ce slider
						clickable: true,
					},
					on: {
						slideChangeTransitionEnd: updatePaginationPosition, // Mets à jour à chaque changement de slide
						resize: updatePaginationPosition, // Mets à jour lors du redimensionnement
					},
				});

				// Mets à jour la position initiale
				updatePaginationPosition();
			} else if (cache.logos) {
				cache.logos.forEach((logo) => {
					logo.style.justifyContent = "flex-start";
				});
			}
		};

		const updatePaginationPosition = () => {
			const activeSlide = cache.sliderQuotes.querySelector(".swiper-slide.swiper-slide-active");
			if (activeSlide && cache.pagination) {
				const slideHeight = activeSlide.offsetHeight; // Hauteur de la slide active

				// Positionne la pagination 20px sous la hauteur de la slide active
				cache.pagination.style.top = `${slideHeight + 32}px`;
			}
		};

		initFunc();
	}
}

export { SliderQuotes };