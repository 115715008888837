class VariationsSelector {
	constructor() {
		let cache = {};
		const initFunc = () => {
			initCache();
			registerEvents();
		};

		const initCache = () => {
			cache.variationForm = document.querySelector(".variations_form.cart");
			cache.swatchesContainers = document.querySelectorAll(
				'.cfvsw-swatches-container[swatches-attr^="attribute_pa_"]'
			);
		};

		const registerEvents = () => {
			if (cache.variationForm && cache.swatchesContainers.length > 0) {
				cache.swatchesContainers.forEach((container) => {
					const swatches = container.querySelectorAll(".cfvsw-swatches-option");

					if (swatches.length > 0) {
						const firstSwatch = swatches[0];
						firstSwatch.click();
					}
				});
			}
		};

		initFunc();
	}
}

export { VariationsSelector };
