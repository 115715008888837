class DynamicSKU {
    constructor() {
        let cache = {};
        const initFunc = () => {
            initCache();
            registerEvents();
            triggerInitialVariation();
        };

        const initCache = () => {
            cache.variationsForm = document.querySelector(".variations_form");
            cache.skuElement = document.getElementById("product-sku");
            cache.swatches = document.querySelectorAll(".cfvsw-swatches-option");
        };

        const registerEvents = () => {
            if (cache.variationsForm) {
                cache.variationsForm.addEventListener("found_variation", handleVariationChange);
                cache.variationsForm.addEventListener("reset_data", resetSKU);
            }

            if (cache.swatches.length > 0) {
                cache.swatches.forEach((swatch) => {
                    swatch.addEventListener("click", handleVariationChange);
                });
            }
        };

        const handleVariationChange = () => {
            // Attendre la mise à jour complète avant de récupérer l'ID de variation
            setTimeout(() => {
                const variationIdInput = document.querySelector(".variations_form input.variation_id");
        
                if (variationIdInput && variationIdInput.value && variationIdInput.value !== "0") {
                    const variationId = variationIdInput.value;
        
                    fetch(woocommerce_params.ajax_url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        body: new URLSearchParams({
                            action: "get_variation_sku",
                            variation_id: variationId,
                        }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.success && data.data.sku) {
                                cache.skuElement.textContent = data.data.sku;
                            } else {
                                cache.skuElement.textContent = "N/A";
                            }
                        })
                        .catch((error) => {
                            console.error("Error fetching SKU:", error);
                            cache.skuElement.textContent = "N/A";
                        });
                }
            }, 100);
        };

        const resetSKU = () => {
            const defaultSku = cache.skuElement.dataset.productId;

            if (!defaultSku) {
                const firstVariationSku = document.querySelector(".variations_form input.variation_id")?.value;
                if (firstVariationSku) {
                    cache.skuElement.textContent = firstVariationSku;
                } else {
                    cache.skuElement.textContent = "N/A";
                }
            } else {
                cache.skuElement.textContent = defaultSku || "N/A";
            }
        };

        const triggerInitialVariation = () => {
            const variationIdInput = document.querySelector(".variations_form input.variation_id");
            if (variationIdInput && variationIdInput.value) {
                handleVariationChange();
            }
        };

        initFunc();
    }
}

export { DynamicSKU };