class QuantityInputs {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.quantityInputs = document.querySelectorAll(
        '.custom-quantity input[type="number"]'
      );
      cache.updateCartButton = document.querySelector(
        'button[name="update_cart"]'
      );
    };

    let registerEvents = () => {
      cache.quantityInputs.forEach(function(input) {
        input.addEventListener("change", function() {
          if (cache.updateCartButton && cache.updateCartButton.disabled) {
            cache.updateCartButton.disabled = false;
          }
          updateCartAjax(input);
        });
      });
    };

    let updateCartAjax = (input) => {
      const cartKey = input.getAttribute("name").match(/\[(.*?)\]/)[1];
      const quantity = input.value;
    
      if (!cartKey || !quantity) {
        console.error("Erreur : cartKey ou quantité non trouvée.");
        return;
      }
    
      jQuery.ajax({
        type: 'POST',
        url: wc_cart_params.ajax_url, 
        data: {
          action: 'update_cart_item', 
          cart_key: cartKey,
          quantity: quantity
        },
        success: (response) => {
          if (response.fragments) {
            // Met à jour le total du panier
            jQuery('.cart_totals').html(response.fragments['.cart_totals']);
    
            // Met à jour la liste des articles du panier
            jQuery('.woocommerce-cart-form').html(response.fragments['.woocommerce-cart-form']);
    
            // Met à jour le mini-panier
            jQuery('.mini-cart').html(response.fragments['.mini-cart']);
          }
        },
        error: (xhr, status, error) => {
          console.error("Erreur AJAX : ", error, xhr.responseText);
        }
      });
    };

    initFunc();
  }
}

export { QuantityInputs };
