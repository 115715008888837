import utils from "../utils/utils";
import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class PopUpLocalisation {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.btnLanguage = document.querySelectorAll(".btn-language");
			cache.popupLocalisation = document.querySelector(".popup-localisation");
			cache.closePopupLocalisation = document.getElementById(
				"close-popup-localisation"
			);

			cache.submitPopup = document.querySelector(".popup-localisation .btn");
			cache.languageSelect = document.getElementById("language-select");
			cache.countrySelect = document.getElementById("country-select");
		};

		let registerEvents = () => {
			setTimeout(() => {
				if (
					!localStorage.getItem("popupValidated") &&
					cache.popupLocalisation
				) {
					cache.popupLocalisation.classList.add("open");
				}
			}, 500);

			if (cache.closePopupLocalisation) {
				cache.closePopupLocalisation.addEventListener(
					"click",
					closePopupLocalisation
				);
			}

			if (cache.btnLanguage) {
				cache.btnLanguage.forEach(btn => {
					btn.addEventListener("click", openPopupLocalisation);
				});
			}

			if (cache.submitPopup) {
				cache.submitPopup.addEventListener("click", changeLanguage);
			}
		};

		let openPopupLocalisation = () => {
			localStorage.removeItem("popupValidated");
			cache.popupLocalisation.classList.add("open");
		};

		let closePopupLocalisation = () => {
			if (cache.popupLocalisation.classList.contains("open")) {
				cache.popupLocalisation.classList.remove("open");
			} else {
				cache.popupLocalisation.classList.add("open");
			}
		};

		let changeLanguage = () => {
			const selectedLanguage = cache.languageSelect.value;
			const selectedCountry = cache.countrySelect.value;

			let targetUrl = "/";

			if (selectedLanguage === "french") {
				targetUrl = "/fr";
			} else if (selectedLanguage === "german") {
				targetUrl = "/de";
			}

			localStorage.setItem("popupValidated", "true");

			window.location.href = targetUrl;
		};

		initFunc();
	}
}
export { PopUpLocalisation };
