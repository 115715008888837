import utils from "../utils/utils";
import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class Blog {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.loadMoreBtn = document.getElementById("load-more");
			cache.postsContainer = document.getElementById("blog-posts");
			cache.filterButtons = document.querySelectorAll(".btn-filter");
		};

		let registerEvents = () => {
			if (!cache.postsContainer) {
				console.error("L'élément #blog-posts n'a pas été trouvé.");
				return;
			}

			cache.loadMoreBtn.addEventListener("click", () => {
				let page = parseInt(cache.loadMoreBtn.getAttribute("data-page")) + 1;
				cache.loadMoreBtn.setAttribute("data-page", page);

				const xhr = new XMLHttpRequest();
				xhr.open("POST", ajax_object.ajax_url, true);
				xhr.setRequestHeader(
					"Content-Type",
					"application/x-www-form-urlencoded; charset=UTF-8"
				);

				xhr.onload = () => {
					if (xhr.status === 200) {
						const response = JSON.parse(xhr.responseText);
						if (response.success) {
							cache.postsContainer.insertAdjacentHTML("beforeend", response.data);
							if (!response.has_more_posts) {
								cache.loadMoreBtn.style.display = "none";
							}
						}
					}
				};

				xhr.send(`action=load_more_posts&page=${page}`);
			});

			cache.filterButtons.forEach((button) => {
				button.addEventListener("click", function() {
					const categoryID = this.getAttribute("data-category");

					cache.postsContainer.innerHTML = "";

					const xhr = new XMLHttpRequest();
					xhr.open("POST", ajax_object.ajax_url, true);
					xhr.setRequestHeader(
						"Content-Type",
						"application/x-www-form-urlencoded; charset=UTF-8"
					);

					xhr.onload = function() {
						if (xhr.status === 200) {
							const response = JSON.parse(xhr.responseText);
							if (response.success) {
								cache.postsContainer.innerHTML = response.data;
							} else {
								cache.postsContainer.innerHTML =
									"<p><?php _e('No posts found', 'yubabikes-theme'); ?></p>";
							}
						}
					};

					const timestamp = new Date().getTime();
					xhr.send(
						`action=filter_posts_by_category&category_id=${categoryID}&_=${timestamp}`
					);
				});
			});
		};

		initFunc();
	}
}
export { Blog };