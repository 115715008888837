class CloseAlertButtons {
  constructor() {
    let cache = {};
    let initFunc = () => {
      initCache();
      registerEvents();
    };

    let initCache = () => {
      cache.closeButtons = document.querySelectorAll(".notice-close");
    };

    let registerEvents = () => {
      cache.closeButtons.forEach(function(button) {
        button.addEventListener("click", (event) => closeAlert(event));
      });
    };

    let closeAlert = (event) => {
      const button = event.target;
      const notice = button.closest(
        ".woocommerce-message, .woocommerce-error, .woocommerce-info"
      );
      if (notice) {
        notice.style.display = "none";
      }
    };

    initFunc();
  }
}
export { CloseAlertButtons };
