import globalVar from "../../../utils/globalVar";

class InfoProductMobile {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.title = document.querySelector(".product_title.entry-title");
			cache.subtitle = document.querySelector(".subtitle");
			cache.price = document.querySelector(".price");
			cache.gallery = document.querySelector(".gallery-mobile");
		};

		let registerEvents = () => {
			rearrangeElements();

			window.addEventListener("resize", function() {
				rearrangeElements();
			});
		};

		let rearrangeElements = () => {
			if (globalVar.tablet.matches) {
				let tempContainer = document.createElement("div");
				tempContainer.classList.add("product-description-mobile");

				if (cache.title) tempContainer.appendChild(cache.title);
				if (cache.subtitle) tempContainer.appendChild(cache.subtitle);
				if (cache.price) tempContainer.appendChild(cache.price);

				if (cache.gallery)
					cache.gallery.parentNode.insertBefore(tempContainer, cache.gallery);
			}
		};

		initFunc();
	}
}
export { InfoProductMobile };
