import anime from "../libs/anime";
import globalVar from "../utils/globalVar";

class TryInStore {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.btnsSidebar = document.querySelectorAll("div.storepoint-btn");
			cache.form = document.querySelector(".form-try-in-store form");
			cache.inputDealer = document.querySelector("input[name='email-dealer']");
		};

		let registerEvents = () => {
			cache.btnsSidebar.forEach((button) => {
				button.addEventListener("mousedown", function (event) {
					console.log("MouseDown détecté sur un bouton.");
					event.preventDefault(); // Évite tout comportement par défaut
					updateMails(event.target);
				});
			});

			const observer = new MutationObserver(() => {
				console.log("Nouveaux éléments détectés. Mise à jour...");
				initCache();
				registerEvents();
			});

			const storepointContainer = document.querySelector(
				".storepoint-container"
			);
			if (storepointContainer) {
				observer.observe(storepointContainer, {
					childList: true,
					subtree: true,
				});
			}
		};

		const updateMails = () => {
			let retries = 0; // Compteur de tentatives
			const maxRetries = 10; // Nombre maximum de tentatives
		
			const checkEmails = setInterval(() => {
				const allEmails = document.querySelectorAll(".storepoint-popup-email");
		
				const filteredEmails = Array.from(allEmails).filter(
					(mailLink) => !mailLink.classList.contains("storepoint-popup-website")
				);
		
				if (filteredEmails.length > 0) {
					filteredEmails.forEach((mailLink) => {
						const email = mailLink.href.replace("mailto:", "").trim();
						console.log("Email extrait :", email);
		
						// Insérer l'email dans l'input
						if (cache.inputDealer) {
							cache.inputDealer.value = email;
							console.log("Email inséré dans l'input :", cache.inputDealer.value);
						} else {
							console.warn("Input `email-dealer` non trouvé.");
						}
					});
		
					clearInterval(checkEmails); // Arrêter les vérifications quand on a trouvé les emails
				} else {
					console.log(`Tentative ${retries + 1}: Aucun email valide trouvé.`);
				}
		
				retries++;
				if (retries >= maxRetries) {
					console.warn("Arrêt des vérifications. Aucune adresse email trouvée.");
					clearInterval(checkEmails); // Stop après maxRetries
				}
			}, 1000); // Vérifie toutes les secondes
		};

		initFunc();
	}
}

export { TryInStore };
