class Home {
	constructor() {
		let cache = {};
		let initFunc = () => {
			initCache();
			registerEvents();
		};

		let initCache = () => {
			cache.page = document.querySelector(".template-home");
		};

		let registerEvents = () => {
		};

		initFunc();
	}
}
export { Home };
